import { Routes } from "src/app/_constants/routes";

export const EMPLOYER_NAV_LINKS = [
  { label: "Dashboard", href: Routes.workspace.dashboard },
  { label: "Messaging", href: Routes.company.messaging },
];

export const EMPLOYER_BRANDED_LINKS = [
  { label: "Job Board", href: Routes.jobs_view },
  { label: "Profile", href: Routes.user.profile },
];

export const JOBSEEKER_NAV_LINKS = [
  { label: "Board", href: Routes.jobs_view },
  { label: "My Jobs", href: Routes.jobseeker.dashboard },
  { label: "Messaging", href: Routes.jobseeker.messaging },
  { label: "Profile", href: Routes.user.profile },
];

export const LOGGED_OUT_NAV_LINKS = [
  { label: "Home", href: Routes.home },
  { label: "Search Jobs", href: Routes.jobs_view },
  { label: "For Employers", href: Routes.landing.findTalent },
  { label: "For Job Seekers", href: Routes.landing.forJobSeekers },
  { label: "Contact Us", href: "https://help.scalis.ai/" },
];

export interface NavLink {
  label: string;
  href: string;
}
