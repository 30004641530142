import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { Routes } from "~/src/app/_constants/routes";
import { getClientSubdomain } from "~/utils/auth/get-client-subdomain";

export enum NavbarType {
    COMPANY_WORKSPACE,
    COMPANY_BRANDED,
    SCALIS_BRANDED,
};

const COMPANY_BRANDED_PATHNAMES = [Routes.jobs_view, Routes.job_details, Routes.user.profile, Routes.auth.login, Routes.auth.signup, ...Object.values(Routes.application_flow)];

const matchPath = (route: string, pathname: string): boolean => {
    const dynamicSegmentPattern = /\/:[^/]+/g; // Matches `/:param` in the route
    const routeRegex = new RegExp(
        `^${route.replace(dynamicSegmentPattern, "/[^/]+")}$`
    ); // Replace `/:param` with a regex that matches any string without slashes
    return routeRegex.test(pathname);
};

const isCompanyBrandedPathname = (pathname: string) => {
    return COMPANY_BRANDED_PATHNAMES.some(route => matchPath(route, pathname));
};

const useNavbarType = (): NavbarType => {
    const pathname = usePathname();
    const [subdomain, setSubdomain] = useState<string | null>(null);
    const [isCompanyBranded, setIsCompanyBranded] = useState<boolean>(false);
  
    useEffect(() => {
        const sd = getClientSubdomain();
        if (sd) setSubdomain(sd);
        if (pathname) setIsCompanyBranded(isCompanyBrandedPathname(pathname));
    }, []);

    if (subdomain && isCompanyBranded) {
        return NavbarType.COMPANY_BRANDED;
    } else if (subdomain) {
        return NavbarType.COMPANY_WORKSPACE;
    } else {
        return NavbarType.SCALIS_BRANDED;
    }
};

export default useNavbarType;