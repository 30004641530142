const excludedSubdomains = ["localhost", "www", "scalis", "dev", "stg"];

export const getClientSubdomain = () => {
    if (typeof window !== 'undefined') {
        const hostname = window.location.hostname;
        const parts = hostname.split(".");
        const potentialSubdomain = parts[0];

        if (!excludedSubdomains.includes(potentialSubdomain)) {
            return potentialSubdomain;
        }
        return undefined;
    }
}