import { deleteCookie } from "cookies-next";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React from "react";
import { useSubdomainRouter } from "~/hooks/use-subdomain-router";
import SubdomainLink from "~/scalis-components/core/subdomain-link/subdomain-link";
import { Routes } from "~/src/app/_constants/routes";

export const AccountDropdownMenuFooter: React.FC = ({}) => {
  const { push } = useSubdomainRouter();

  const handleLogout = async () => {
    await signOut({ redirect: false });

    push(Routes.home, '');
  };

  return (
    <div className="flex w-full justify-between p-4">
      <SubdomainLink
        className="flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest"
        href="/public/pp"
        subdomain=""
      >
        Privacy Policy
      </SubdomainLink>
      <button
        type="button"
        className="flex cursor-pointer items-center gap-2 text-sm font-medium text-brand-primary-rest"
        onClick={() => handleLogout()}
      >
        Log Out
        <i className="fa-regular fa-arrow-right-from-bracket" />
      </button>
    </div>
  );
};
