"use client";

import { AccountButtonProps } from "./account-button.types";
import { AccountAvatar } from "./components/account-avatar";

export const AccountButton = ({
  imageSrc = "",
  name,
  onClick,
  ...props
}: AccountButtonProps) => {
  return (
    <div
      className="group relative flex h-9 !w-9 cursor-pointer justify-center rounded-full border-2 border-neutral-10 p-0.5 hover:border-brand-primary-10"
      onClick={onClick}
      role="button"
      aria-label="account button"
    >
      <AccountAvatar imageSrc={imageSrc} name={name} {...props} />
    </div>
  );
};
