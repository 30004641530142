import Link from "next/link";
import Image from "next/image";
import { Routes } from "~/src/app/_constants/routes";

export const Logo = () => (
  <Link href={Routes.home}>
    <Image
      src="https://scalis-assets.s3.us-east-2.amazonaws.com/public/scalis_brand.svg"
      alt="Scalis Logo"
      width={118}
      height={24}
      priority
      quality={100}
    />
  </Link>
);
