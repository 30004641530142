import { useRouter } from "next/navigation";


export const getSubdomainUrl = (path: string, subdomain: string | null) => {
    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.host;

    // fallback to default behavior on localhost
    if (currentDomain.includes('localhost')) return path;

    const baseDomain = process.env.NEXT_PUBLIC_DOMAIN || "scalis.loc:3000";

    if (!subdomain) return `${currentProtocol}//${baseDomain}${path}`;
    return `${currentProtocol}//${subdomain}.${baseDomain}${path}`;
};

export const useSubdomainRouter = () => {
    const router = useRouter();

    const push = (path: string, subdomain: string) => {
        const urlOrPath = getSubdomainUrl(path, subdomain);

        return router.push(urlOrPath);
    };

    return { ...router, push };
};
