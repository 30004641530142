/** @type {import('tailwindcss').Config} */
module.exports = {
  theme: {
    extend: {
      colors: {
        brand: {
          primary: {
            rest: "#0021f8",
            10: "#0015A4",
          },
        },
        "data-viz": {
          violet: {
            30: "#BE95FF",
          },
        },
        neutral: {
          rest: "#393b40",
          primary: "#1f2124",
          secondary: "#606e7f",
          tertiary: "#94A3B8",
          disabled: "#C3CFDC",
          placeholder: "#D1DAE4",
          "on-brand": "#fff",
          30: "#DDE5ED",
          overlay: "#000000",
        },
        status: {
          critical: "#ed4014",
          success: "#2EC780",
          warning: "#FAC019",
        },
        icon: {
          neutral: {
            "00": "#D1DAE4",
            10: "#606E7F",
            20: "#1f2124",
            disabled: "#C3CFDC",
          },
        },
        dataviz: {
          pink: {
            DEFAULT: "#FFCCE8",
            10: "#FFE5F4",
            30: "#FF7EC5",
            40: "#F265B2",
          },
          violet: {
            DEFAULT: "#E0CCFF",
            10: "#EFE5FF",
            20: "#E0CCFF",
            30: "#D0B3FF",
            40: "#BC94FE",
          },
          lilac: {
            DEFAULT: "#C9D5FB",
          },
          cyan: {
            DEFAULT: "#B2E8FF",
            10: "#CCF0FF",
            30: "#7DD5FB",
            40: "#6EC1E5",
          },
          teal: {
            DEFAULT: "#ACFAF9",
            10: "#CCFFFE",
            30: "#81F4F2",
            40: "#61EFEC",
          },
          lime: {
            DEFAULT: "#A6FFC0",
            10: "#CCFFDA",
            30: "#6DF694",
            40: "#47E172",
          },
          yellow: {
            DEFAULT: "#D7FF9E",
            10: "#EAFFCC",
            30: "#C6F87F",
            40: "#B3F05D",
          },
          "soft-orange": {
            10: "#FFDBA6",
          },
          "leaf-green": {
            10: "#ADF995",
          },
          "cherry-red": {
            10: "#FFB5B3",
          },
          "yolk-yellow": {
            10: "#FFE89E",
          },
          "neon-yellow": {
            10: "#FEF9A9",
          },
          "lime-green": {
            10: "#ECF9A0",
          },
          "moss-green": {
            10: "#D3FF98",
          },
          "lilac-blue": {
            DEFAULT: "#C9D5FB",
            40: "#7A9AFF",
          },
          score: {
            90: "#78CE5C",
          },
        },
        surface: {
          warning: "#FFE9BD",
          success: "#A3FFD4",
          critical: "#FFC2B2",
        },
      },
      boxShadow: {
        outline: "0px 0px 0px 2px #fff, 0px 0px 0px 4px #0021f8",
        "tab-active":
          "0px 1px 3px rgba(18, 18, 23, 0.1), 0px 1px 2px rgba(18, 18, 23, 0.06)",
      },
      lineHeight: {
        DEFAULT: "24px",
      },
      backgroundColor: {
        "neutral-00": "#ffffff",
        "neutral-10": "#f5f7fa",
        "neutral-20": "#EEF3F8",
        "neutral-30": "#DDE5ED",
        "neutral-disabled": "#d1dae4",
        "brand-primary-dark-10": "#001aca",

        "brand-primary-dark-00": "#0021f8",
        "brand-primary-light-00": "#f1f7fd",
        "brand-primary-light-10": "#E7F3FF",

        "surface-neutral-rest": "#f5f7fa",
        "surface-brand-rest": "#e7f3ff",
        "surface-brand-hover": "#d3e9ff",

        "button-brand-primary-dark-rest": "#0021f8",
        "button-brand-primary-dark-hover": "#001aca",
        "button-brand-primary-dark-pressed": "#0015a4",

        "button-brand-primary-light-rest": "#e7f3ff",
        "button-brand-primary-light-hover": "#d3e9ff",
        "button-brand-primary-light-pressed": "#b0d5ff",

        "button-subtle-rest": "#fff",
        "button-subtle-hover": "#eef3f8",
        "button-subtle-pressed": "#dde5ed",

        "button-status-critical-rest": "#ed4014",
        "button-status-critical-hover": "#d9340b",
        "button-status-critical-pressed": "#b22400",

        "button-dark-00": "#393B40",

        "button-disabled": "#eef3f8",

        "dataviz-violet-10": "#EFE5FF",
        "dataviz-violet-30": "#D0B3FF",
        "dataviz-pink-10": "#FFE5F4",
        "dataviz-pink-30": "#FF7EC5",
        "dataviz-cyan-10": "#CCF0FF",
        "dataviz-cyan-30": "#7DD5FB",
        "dataviz-teal-10": "#CCFFFE",
        "dataviz-teal-30": "#81F4F2",
        "dataviz-lime-10": "#CCFFDA",
        "dataviz-lime-30": "#6DF694",
        "dataviz-yellow-10": "#EAFFCC",
        "dataviz-yellow-30": "#C6F87F",
      },
      textColor: {
        "neutral-inverse-dark": "#1F2124",
        "neutral-secondary": "#606E7F",
        "neutral-tertiary": "#94A3B8",
        "brand-primary-rest": "#0021F8",
      },
      borderColor: {
        "neutral-00": "#DDE5ED",
        "neutral-rest": "#D1DAE4",
        "neutral-10": "#C3CFDC",
        "neutral-hover": "#A0AAB5",
        "neutral-disabled": "#eef3f8",
        "brand-primary-dark-10": "#001aca",

        "brand-primary-light-hover": "#D3E9FF",

        //TODO: this is a mistake from design system,
        //as soon as they replace it for a better token, update it here
        "bg-neutral-30": "#DDE5ED",
        "bg-neutral-10": "#C3CFDC",
        "bg-brand-primary-dark-00": "#0021f8",
        "brand-primary-rest": "#0021F8",

        "brand-primary-light-pressed": "#B0D5FF",

        "bg-button-dark-00": "#393B40",
        "status-success-10": "#2EC780",
        "status-critical-10": "#ED4014",
        "status-warning-10": "#FAC019",
      },
    },
  },
};
