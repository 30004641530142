"use client";

import React, { useContext, useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import {
  NavbarContextProps,
  NavbarProviderProps,
  Workspace,
} from "./navbar-context.types";

export const NavbarContext = React.createContext<NavbarContextProps | null>(null);

export const USER_ROLE_COMPANY_WORKSPACE = "CompanyWorkspace";
export const USER_ROLE_JOB_SEEKER = "JobSeeker";

export const NavbarProvider = ({ children, defaultValues }: NavbarProviderProps) => {
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(
    defaultValues?.currentWorkspace ?? null
  );
  const [workspaces, setWorkspaces] = useState<Workspace[] | null>(
    defaultValues?.workspaces ?? []
  );
  const [userRole, setUserRole] = useState<NavbarContextProps["userRole"]>(null);

  useEffect(() => {
    if (currentWorkspace) {
      setUserRole(USER_ROLE_COMPANY_WORKSPACE);
    } else {
      setUserRole(USER_ROLE_JOB_SEEKER);
    }
  }, [currentWorkspace]);

  const contextValue = useMemo(
    () => ({
      ...defaultValues,
      currentWorkspace,
      workspaces,
      setWorkspaces,
      userRole,
      setUserRole,
      setCurrentWorkspace,
    }),
    [currentWorkspace, workspaces, userRole]
  );

  return (
    <NavbarContext.Provider value={contextValue}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => {
  const context = useContext(NavbarContext) ?? ({} as NavbarContextProps);
  if (context == null) {
    throw new Error(
      "useNavbarContext can only be used within a NavbarProvider"
    );
  }
  return context;
};
