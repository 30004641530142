"use client";

import React from "react";
import Link from "next/link";
import useBreakpoint from "~/hooks/useBreakpoint";
import { Divider } from "~/scalis-components/core/divider";
import { Caption, CaptionColor } from "~/scalis-components/core/typography";
import { cn } from "~/utils/cn";
import { useNavbarContext } from "../../navbar-context";
import { AccountDropdownMenuProps } from "../account-dropdown-menu.types";
import { AccountAvatar } from "./account-button";

import { Workspace } from "../../navbar-context/navbar-context.types";
import { useSession } from "next-auth/react";
import { Routes } from "~/src/app/_constants/routes";
import { useSubdomainRouter } from "~/hooks/use-subdomain-router";
import SubdomainLink from "~/scalis-components/core/subdomain-link/subdomain-link";
import useNavbarType, { NavbarType } from "~/hooks/use-navbar-type";

export const AccountDropdownMenuContent: React.FC<AccountDropdownMenuProps> = ({
  imageSrc,
  name,
  email,
}) => {
  const navbarType = useNavbarType();
  const { push } = useSubdomainRouter();
  const { workspaces, setCurrentWorkspace } = useNavbarContext();
  const { lg } = useBreakpoint();
  const { update } = useSession();

  const clearCurrentWorkspace = () => {
    update({
      subdomain: null,
    });
  };

  const handleWorkspaceChange = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    workspace: Workspace,
  ) => {
    e.preventDefault();

    await update({
      subdomain: workspace.subdomain,
    });
    setCurrentWorkspace(workspace);

    push("/company/home/active", workspace.subdomain);
  };

  return (
    <>
      <div
        className={cn("flex gap-2 p-4", {
          "gap-3": !lg,
        })}
      >
        <AccountAvatar
          name={name}
          imageSrc={imageSrc}
          avatarClassName={cn("h-10 w-10", {
            "h-14 w-14 items-base": !lg,
          })}
        />
        <div className="flex flex-col">
          <span className="text-base/6 font-medium text-neutral-primary">
            {name}
          </span>
          <Caption color={CaptionColor.secondary}>{email}</Caption>
          <SubdomainLink
            subdomain=""
            href={Routes.user.profile}
            onClick={e => clearCurrentWorkspace()}
            className="mt-1 text-sm/6 font-normal text-brand-primary-rest underline"
          >
            SCALIS Profile
          </SubdomainLink>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col gap-2 p-4">
        <h1 className="text-sm uppercase leading-4 text-neutral-secondary">
          Account
        </h1>
        <div className="flex items-center gap-2 text-sm font-normal text-neutral-primary">
          <i className="fa-regular fa-user" />
          <SubdomainLink
            subdomain=""
            href={Routes.user.settings.account_information}
            className="hover:underline"
          >
            User Settings
          </SubdomainLink>
        </div>
        <div className="flex items-center gap-2 text-sm font-normal text-neutral-primary">
          <i className="fa-regular fa-lock-keyhole" />
          <SubdomainLink
            subdomain=""
            href={Routes.user.settings.sign_in_and_security}
            className="hover:underline"
          >
            Sign In & Security
          </SubdomainLink>
        </div>
      </div>

      {navbarType !== NavbarType.COMPANY_WORKSPACE && !!workspaces?.length ? (
        <>
          <Divider />
          <div className="flex flex-col gap-2 p-4">
            <h1 className="text-sm uppercase leading-4 text-neutral-secondary">
              Company Workspace
            </h1>
            {workspaces.map(workspace => (
              <button
                key={workspace.id}
                onClick={(e: any) => handleWorkspaceChange(e, workspace)}
                className="text-sm font-normal text-neutral-primary hover:underline text-start"
              >
                {workspace.subdomain}
                <span className="text-neutral-secondary">{` (${workspace.id})`}</span>
              </button>
            ))}
            {process.env.NEXT_PUBLIC_ENV === 'local' && (
              <Link className="text-sm font-normal text-neutral-primary hover:underline text-start" href={Routes.workspace.create}>
                Create Workspace [DEV ONLY]
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          {process.env.NEXT_PUBLIC_ENV === 'local' && (
            <>
              <Divider />
              <div className="flex flex-col gap-2 p-4">
                <SubdomainLink className="text-sm font-normal text-neutral-primary hover:underline text-start" subdomain="" href={Routes.workspace.create}>
                  Create Workspace [DEV ONLY]
                </SubdomainLink>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
