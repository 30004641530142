import { useSession } from "next-auth/react";

import {
  NavLink,
  EMPLOYER_NAV_LINKS,
  JOBSEEKER_NAV_LINKS,
  LOGGED_OUT_NAV_LINKS,
  EMPLOYER_BRANDED_LINKS,
} from "./navbar-default.constants";
import useNavbarType, { NavbarType } from "~/hooks/use-navbar-type";

export const useNavLinks = (): NavLink[] => {
  const { status, data } = useSession();
  const isSignedIn = status === "authenticated" && data.user.emailVerified;

  const navbarType = useNavbarType();

  if (!isSignedIn) {
    return LOGGED_OUT_NAV_LINKS;
  }

  if (navbarType === NavbarType.COMPANY_WORKSPACE) {
    return EMPLOYER_NAV_LINKS;
  } 

  if (navbarType === NavbarType.COMPANY_BRANDED) {
    return EMPLOYER_BRANDED_LINKS;
  }

  return JOBSEEKER_NAV_LINKS;
};
